<template>
  <div :style="computedStyle" v-show="!isHidden" :class="CSSClasses">
    <el-checkbox v-bind:value="!!value" :size="size" :disabled="readonly"
                v-on:input="$emit('input', $event)" :name="name">{{ label }}</el-checkbox>
    <el-tooltip v-if="tooltip" style="margin-left: 5px" effect="dark" :content="tooltip">
      <i class="el-icon-question"></i>
    </el-tooltip>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
export default {
  name: 'boolean_field',
  mixins: [mixin, registryMixin],
  props: {
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    label: {
      description: 'Название',
      type: String
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
